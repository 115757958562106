<template>
  <div
    v-if="order"
    class="add-new-item-admin"
  >
    <title-bar
      :submitBtn="submitBtn"
      :submitBtnTitle="$t('messages.orderUpdate')"
      :title="$t('messages.orderUpdate')"
      @submitPressed="submitPressed"

      :thirdBtn="order ? true : false"
      :thirdBtnTitle="$t('pdf.printOrder')"
      @thirdPressed="printOrderPdf"

      :secondaryBtn="orderPdf ? true : false"
      :secondaryBtnTitle="$t('pdf.deleteOrderPdf')"
      @secondaryPressed="deleteOrderPdf"

      :fourthBtn="order ? true : false"
      :fourthBtnTitle="$t('order.manageStock')"
      @fourthPressed="manageStock"

    />
    <b-modal
        v-model="modalShow"
        modal-class="manage-stock-dialog"
        cancel-title="Cancel"
        ok-title="Confirm"
        @ok="confirmManageStock"

    >
      <h3>{{ $t("order.manageStock") }}</h3>
      <table-lite
          :is-slot-mode="true"
          :columns="columns"
          :rows="stockedItems"
          :sortable="{}"
      >
        <template v-slot:itemName="data">
          {{ data.value.orderItem.itemName }}
        </template>
        <template v-slot:quantity="data">
          <b-form-input
              type="number"
              min="1"
              step="1"
              v-model="data.value.quantity"
          />
        </template>
        <template v-slot:outstandingQuantity="data">
          {{ data.value.orderItem.quantity - (data.value.orderItem.stockedQuantity ?? 0) }}
        </template>
      </table-lite>
    </b-modal>
    <order-form :order-obj="order" :action="action" @clearAction="clearAction" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import OrderForm from '@/views/Admin/Orders/components/OrderForm.vue';
import ResourceUtils from "@/mixins/ResourceUtils";
import TableLite from "vue3-table-lite";
import {
  BFormInput,
} from "bootstrap-vue-3";
import {getUserData} from "@/auth/utils"

export default {
  components: {
    TableLite,
    TitleBar,
    OrderForm,
    BFormInput
  },
  data() {
    return {
      action: null,
      order: null,
      orderPdf: null,
      modalShow: false,
      submitBtn: true,
      stockedItems: [],
      printPdf: {
        templateName: 'ORDER'
      },
      columns: [
        {
          label: this.$t("product.item"),
          field: "itemName",
          sortable: false,
        },
        {
          label: this.$t("forms.stockedQuantity"),
          field: "quantity",
          sortable: false,
        },
        {
          label: this.$t("forms.outstandingQuantity"),
          field: "outstandingQuantity",
          sortable: false,
        },
      ]
    }
  },
  mixins: [ResourceUtils],
  created() {
    this.load()
  },
  methods: {
    setPermissions() {
      const userData = getUserData()
      if (
          false === this.$helper.isRoleAdmin() &&
          this.order.status === 'PENDING' &&
          false === userData.permissionRoles.includes(`/roles/${process.env.VUE_APP_EMPLOYEE_MANAGER_ROLE_ID}`)
      ) {
        this.submitBtn = false
      }
    },
    load() {
      const id = this.$route.params.id;
      this.$Orders.getResource({ id }, 'order_edit').then((response) => {
        this.order = response.data
        this.order.fromDate = new Date(response.data.fromDate)
        this.order.toDate = new Date(response.data.toDate)
        this.order.statusSelect = {
          label: this.$helper.getEnumTranslation('ORDER_STATUS', this.order.status, this.$i18n.locale),
          id: this.order.status
        }
        this.order.vendorSelect = {
          label: this.order.vendor.name,
          id: this.order.vendor['@id'],
          numberId: this.order.vendor.id
        }
        this.order.itemsSelect = []
        this.order.items.forEach((orderItem, index) => {
          this.order.items[index].itemSelect = {
            label: orderItem.item.number,
            id: orderItem.item['@id'],
            numberId: orderItem.item.id,
            item: orderItem.item
          }

          this.stockedItems[index] = {
            orderItem: orderItem,
            item: orderItem.item,
            quantity: 0
          }
        })
        this.setOrderPdf()
        this.setPermissions()
      });
    },
    clearAction() {
      this.action = null;
      this.load();
    },
    submitPressed() {
      this.action = 'update'
    },
    setOrderPdf() {
      const body = {
        name: `${this.printPdf.templateName.toLowerCase()}_${this.order.id}`,
        templateName: this.printPdf.templateName,
        resource: this.order["@id"],
      };

      this.$Pdfs
          .getResourceByUrl({ url: `/pdfs/${body.name}` })
          .then((response) => {
            this.orderPdf = response.data
          })
    },
    printOrderPdf() {
      const body = {
        name: `${this.printPdf.templateName.toLowerCase()}_${this.order.id}`,
        templateName: this.printPdf.templateName,
        resource: this.order["@id"],
      };

      this.$Pdfs
          .getResourceByUrl({ url: `/pdfs/${body.name}` })
          .then((response) => {
            const link = document.createElement("a");
            link.href = response.data.notCachedUrl;
            link.target = "_blank";
            link.click();
          })
          .catch(() => {
            this.$Pdfs.createResource({ body }).then((response) => {
              if (response.status === 201) {
                this.orderPdf = response.data
                const link = document.createElement("a");
                link.href = response.data.notCachedUrl;
                link.target = "_blank";
                link.click();
              }
            });
          })
    },
    deleteOrderPdf() {
      this.deleteByUrl(
          this.$Pdfs,
          `/pdfs/${this.printPdf.templateName.toLowerCase()}_${this.order.id}`,
          this.$t("pdf.orderPdfDeleted"),
          null,
          () => {
            this.orderPdf = null
          }
      );
    },
    manageStock() {
      this.modalShow = true
    },
    confirmManageStock() {
      this.stockedItems.forEach((item, index) => {
        const currentStockedQuantity = item.orderItem.stockedQuantity ? parseInt(item.orderItem.stockedQuantity) : 0
        const newStockedQuantity = parseInt(item.quantity)
        const orderItem = this.order.items[index]
        orderItem.item = orderItem.item['@id']
        orderItem.stockedQuantity = currentStockedQuantity + newStockedQuantity
        orderItem.addStockQuantity = newStockedQuantity
        this.order.items[index] = orderItem
        this.order.vendor = this.order.vendorSelect.id
      })
      const body = {
        items: this.order.items
      }
      return this.update(this.$Orders, this.order.id, body, this.$t("messages.orderUpdated"), null, this.success);
    },
    success() {
      this.$router.go(0)
    }
  }
};
</script>

<style lang="scss">
</style>
