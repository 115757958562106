<template>
  <b-form @submit.prevent="onSubmit" v-if="order" class="order-form">
    <b-container class="full-w">
      <b-row>
        <b-col cols="12" lg="8">
          <b-card>
            <h3>{{ $t("forms.items") }}</h3>

              <b-form-group class="add-product-group" v-if="canEdit">
                <label for="">{{ $t("forms.item") }}</label>
                <custom-select
                    id="selectedProduct"
                    :value="selectedProduct"
                    :options="products"
                    resource="$Items"
                    resourceType="item"
                    entityType="SPARE_PART"
                    @input="setSelectedProduct($event)"
                />
              </b-form-group>

            <b-button
                class="add-product-btn"
                :disabled="!selectedProduct"
                @click="addProductToOrder"
                variant="secondary"
                v-if="canEdit"
            >{{ $t("product.add") }}
            </b-button>

            <b-alert
                class="mt-3"
                variant="danger"
                :show="v$.orderProducts.$errors.length"
            >{{ $t("order.minProductWarning") }}
            </b-alert
            >

            <div
                v-if="orderProducts && orderProducts.length"
                class="custom-table"
            >
              <table-lite
                  :is-slot-mode="true"
                  :columns="columns"
                  :rows="orderProducts"
                  :sortable="{}"
              >
                <template v-slot:itemName="data">
                  <a :href="$helper.getItemEditLink(data.value.item)">{{ data.value.itemName }}</a>
                </template>
                <template v-slot:leadTime="data">
                  <b-form-input
                      v-model="data.value.leadTime"
                  />
                </template>
                <template v-slot:quantity="data">
                  <b-form-input
                      type="number"
                      min="1"
                      step="1"
                      v-model="data.value.quantity"
                  />
                </template>
                <template v-slot:stockedQuantity="data">
                  <div v-if="data.value.stockedQuantity">{{ data.value.stockedQuantity }}</div>
                  <div v-else>0</div>
                </template>
                <template v-slot:outstandingQuantity="data">
                  {{ data.value.quantity - (data.value.stockedQuantity ?? 0) }}
                </template>
                <template v-slot:price="data">
                  <b-form-input
                      v-model="data.value.price"
                  />
                </template>
                <template v-slot:priceTotal="data">
                  <div v-if="data.value.quantity && data.value.price">{{ $helper.priceFormat(data.value.quantity * data.value.price, 2) }}</div>
                  <div v-else>{{ $helper.priceFormat(0) }}</div>
                </template>
                <template v-slot:actions="data">
                  <div class="just-buttons">
                    <b-button variant="secondary" @click="removeOrderProduct(data.value.id)">
                      <bootstrap-icon
                          icon="trash"
                          size="1x"
                      />
                    </b-button>
                  </div>
                </template>
              </table-lite>
            </div>
            <div
                v-if="orderProducts && orderProducts.length"
                class="order-total"
            >
              <div>{{ $t("order.total") }}</div>
              <div>{{ $helper.priceFormat(orderTotal, 2) }}</div>
            </div>
          </b-card>
          <b-card>
            <h3>{{ $t("order.notes") }}</h3>
            <b-form-group
                label-for="notes"
            >
              <b-form-textarea v-model="order.notes" id="notes" />
            </b-form-group>
          </b-card>
          <b-card>
            <h3>{{ $t("order.comments") }}</h3>
            <b-form-group
                label-for="comments"
            >
              <b-form-textarea v-model="order.comments" id="comments" />
            </b-form-group>
          </b-card>
          <b-card
            v-if="order.histories"
          >
            <h3>{{ $t("order.statusChangesHistories") }}</h3>
            <fieldset>
              <table class="product-table">
                <thead>
                  <tr>
                    <th>{{ $t("order.changedBy") }}</th>
                    <th>{{ $t("order.statusChangedTo") }}</th>
                    <th>{{ $t("order.changedAt") }}</th>
                  </tr>
                </thead>
                <tr
                    v-for="history of order.histories"
                    :key="history"
                >
                  <td class="width-20">
                    {{ history.user.givenName }} {{ history.user.familyName }}
                  </td>
                  <td class="width-20">
                    {{ $helper.getEnumTranslation("ORDER_STATUS", history.status, this.$i18n.locale) }}
                  </td>
                  <td class="width-20">
                    {{ $helper.formatDate(history.createdAt) }}
                  </td>
                </tr>
              </table>
            </fieldset>
          </b-card>
        </b-col>
        <b-col cols="12" lg="4">
          <b-card>
            <b-form-group
                v-if="type === 'update'"
                :label="$t('forms.orderId')"
                label-for="h-id"
            >
              <b-form-input v-model="order.id" id="h-id" disabled/>
            </b-form-group>
            <b-form-group
                :class="{ row, error: v$.order.statusSelect.$errors.length }"
                :label="$t('forms.status')"
                label-for="h-status"
            >
              <v-select
                  id="h-status"
                  v-model="order.statusSelect"
                  :options="statuses"
              />
              <div
                  class="input-errors"
                  v-for="error of v$.order.statusSelect.$errors"
                  :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>
            <b-form-group
                :class="{ row, error: v$.order.vendorSelect.$errors.length }"
                :label="$t('forms.vendor')"
                label-for="h-vendor"
            >
              <v-select
                id="h-vendor"
                v-model="order.vendorSelect"
                :options="vendors"
              />
              <div
                  class="input-errors"
                  v-for="error of v$.order.vendorSelect.$errors"
                  :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>
            <b-form-group
                v-if="type === 'update'"
                :label="$t('order.createdAt')"
                label-for="h-hash"
            >
              <datepicker
                  v-model="order.createdAt"
                  :enableTimePicker="true"
                  class="form-input"
                  format="dd. MM. yyyy HH:mm:ss"
                  previewFormat="dd. MM. yyyy HH:mm:ss"
                  disabled
              ></datepicker>
            </b-form-group>
            <b-form-group
                v-if="type === 'update'"
                :label="$t('order.updatedAt')"
                label-for="h-hash"
            >
              <datepicker
                  v-model="order.updatedAt"
                  :enableTimePicker="true"
                  class="form-input"
                  format="dd. MM. yyyy HH:mm:ss"
                  previewFormat="dd. MM. yyyy HH:mm:ss"
                  disabled
              ></datepicker>
            </b-form-group>
            <b-form-group
                v-if="type === 'update' && order.sentAt"
                :label="$t('order.sentAt')"
                label-for="h-hash"
            >
              <datepicker
                  v-model="order.sentAt"
                  :enableTimePicker="true"
                  class="form-input"
                  format="dd. MM. yyyy HH:mm:ss"
                  previewFormat="dd. MM. yyyy HH:mm:ss"
                  disabled
              ></datepicker>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue-3";
import vSelect from "vue-select";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import TableLite from "vue3-table-lite";
import CustomSelect from "@/components/Inputs/CustomSelect.vue";

export default {
  components: {
    CustomSelect,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    Datepicker,
    TableLite
  },
  props: {
    orderObj: {
      type: Object, default() {
      }
    },
    action: {type: String, default: null},
    type: {type: String, default: 'update'},
  },
  data() {
    return {
      order: this.orderObj,
      title: "",
      statuses: [],
      vendors: [],
      products: [],
      orderProducts: [],
      selectedProduct: null,
      columns: [
        {
          label: this.$t("product.item"),
          field: "itemName",
          sortable: false,
        },
        {
          label: this.$t("product.text"),
          field: "leadTime",
          sortable: false,
          width: "250px"
        },
        {
          label: this.$t("product.quantity"),
          field: "quantity",
          sortable: false,
          width: "100px"
        },
        {
          label: this.$t("forms.stockedQuantity"),
          field: "stockedQuantity",
          sortable: false,
        },
        {
          label: this.$t("forms.outstandingQuantity"),
          field: "outstandingQuantity",
          sortable: false,
        },
        {
          label: this.$t("product.price"),
          field: "price",
          sortable: false,
          width: "150px"
        },
        {
          label: this.$t("product.priceTotal"),
          field: "priceTotal",
          sortable: false,
        },
      ]
    };
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      orderProducts: {required},
      order: {
        statusSelect: {required},
        vendorSelect: {required},
      },
    };
  },
  created() {
    this.getOrderStatuses(this.order, this.statuses);
    this.getResourceList(this.$Vendors, this.vendors)

    if (this.order.items) {
      this.order.items.forEach((orderItem) => {
        this.orderProducts.push({
          id: orderItem.id,
          item: orderItem.item,
          itemId: orderItem.itemSelect.id,
          quantity: parseInt(orderItem.quantity),
          stockedQuantity: orderItem.stockedQuantity ? parseInt(orderItem.stockedQuantity) : 0,
          price: orderItem.price,
          leadTime: orderItem.leadTime,
          itemName: orderItem.itemName,
        })
      });
    }

    if (this.canEdit) {
      this.columns.push({
        label: this.$t("order.actions"),
        field: "actions",
        sortable: false,
        width: "120px",
      });
    }
  },
  watch: {
    action() {
      this.onSubmit();
    }
  },
  computed: {
    canEdit() {
      return this.type === 'create' || (this.type === 'update' && this.order.status === 'NEW')
    },
    user() {
      return this.order.user
    },
    vendor() {
      return this.order.vendor
    },
    orderTotal() {
      return this.$helper.getOrderTotal(this.orderProducts)
    },
  },
  methods: {
    setSelectedProduct($event) {
        this.selectedProduct = $event
    },
    addProductToOrder() {
      const product = this.selectedProduct.item;
      product.price = null;
      product.quantity = null;
      product.stockedQuantity = 0;
      product.leadTime = null;
      product.itemName = this.selectedProduct.label;
      product.itemId = this.selectedProduct.item['@id'];
      product.item = this.selectedProduct.item;

      if (this.order.vendorSelect && this.order.vendorSelect.id) {
        const vendor = this.vendors.find((ve) => ve.id === this.order.vendorSelect.id);
        if (vendor) {
          const vendorData = product.vendors.find((pv) => pv.vendor['@id'] === vendor.id);
          if (vendorData) {
            product.price = vendorData.price;
            product.quantity = vendorData.reqQuantity;
            product.leadTime = vendorData.leadTime;
          }
        }
      }

      this.orderProducts.push(product)

      const index = this.products.findIndex((op) => op.id === this.selectedProduct.id);
      if (index >= 0) {
        this.products.splice(index, 1);
      }

      this.selectedProduct = null
    },
    removeOrderProduct(id) {

      const find = this.orderProducts.find((op) => op.id === id)

      if (find) {
        const itemCategory = this.$helper.getEnumTranslation("ITEM_CATEGORY", find.itemCategory, this.$i18n.locale)
        const name = find.name ? find.name : ''
        const number = find.number ? find.number : ''

        const product = {
          label: `${itemCategory} - ${name} ${number}`,
          id: find['@id'],
          numberId: find.id,
          item: find
        }
        this.products.push(product)
      }

      const index = this.orderProducts.findIndex((op) => op.id === id);
      if (index >= 0) {
        this.orderProducts.splice(index, 1);
      }
    },
    updateOrder(id, body) {
      return this.update(this.$Orders, id, body, this.$t("messages.orderUpdated"), null, this.success, this.error);
    },
    createOrder(body) {
      return this.create(this.$Orders, body, this.$t("messages.orderCreated"), null, this.redirect, this.error)
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          vendor: this.order.vendorSelect.id,
          status: this.order.statusSelect.id,
          items: this.order.items,
          notes: this.order.notes,
          comments: this.order.comments,
        };

        body.items = this.orderProducts.map((product) => {
          return {
            item: product.itemId,
            quantity: parseInt(product.quantity),
            stockedQuantity: parseInt(product.stockedQuantity),
            price: product.price,
            leadTime: product.leadTime,
            itemName: product.itemName,
          }
        });

        if (this.action === "add") {
          this.createOrder(body)
        }
        if (this.action === "update") {
          const id = this.$route.params.id;
          this.updateOrder(id, body);
        }
      } else {
        this.$emit("clearAction");
      }
    },
    redirect() {
      this.$router.push(`/orders`);
    },
    success() {
      if (this.order.statusSelect.id === 'DONE') {
        this.$router.go(0)
      }

      this.statuses = []
      this.order.status = this.order.statusSelect.id
      this.getOrderStatuses(this.order, this.statuses);

      if (!this.canEdit) {
        this.columns.forEach((column, index) => {
          if (column.field === 'actions') {
            this.columns.splice(index, 1);
          }
        });
      }

      this.$emit("clearAction");
    },
    error() {
      this.$emit("clearAction");
    }
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
